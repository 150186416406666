import React, { PropsWithChildren, useState, ReactElement } from "react";
import styles from "./AccordionItem.module.scss";
import { chevron } from "../../../shared/icons";

interface AccordionItemData {
    title: string;
    information: string;
}
function AccordionItem(props: PropsWithChildren<AccordionItemData>) {
    const [showData, setShowData] = useState(false as boolean);
    const { title, information } = props;
    return (
        <>
            <div
                className={styles.accordionItem}
                onClick={() => setShowData(!showData)}
            >
                <h3 className={styles.accordionItemTitle}>{title}</h3>
                <div
                    className={`${styles.svgWrapper} ${
                        showData ? styles.toggled : ""
                    }`}
                >
                    {chevron.down}
                </div>
            </div>
            {showData && (
                <p className={styles.accordionItemInformation}>{information}</p>
            )}
        </>
    );
}

export default AccordionItem;
