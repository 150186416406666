import React, { PropsWithChildren, ReactElement } from "react";
import useWindowWidth, { heroDesktopMinWidth } from "../../hooks/useWindowWidth";
import { graphql } from "gatsby"
import styles from "./FAQs.module.scss"
import Helmet from "../../components/Helmet";
import Hero from "../../components/shared/HeroStaticPages";
import AccordionItem from "../../components/shared/AccordionItem"
import { getSeoDescription, getSeoKeywords, getSeoTitle } from "../../shared/helpers";



const Faqs = (props: PropsWithChildren<any>): ReactElement => {
    const data = JSON.parse(props.data.allPrismicFaqsPage.edges[0].node.dataString);

    const isDesktop = useWindowWidth(heroDesktopMinWidth)
    const background = isDesktop ? data.hero_background?.url : data.mobile_background?.url || data.hero_background?.url

    return (
        <>
            <Helmet
                title={getSeoTitle(data, "FAQs")}
                description={getSeoDescription(data, "Frequently Asked Questions page")}
                keywords={getSeoKeywords(data, "")} 
            />
            
            {background && <Hero img={background} />}
            <main className={`mainBox ${styles.mainWrapper}`}>

                {data.informative_text &&
                    <div className={styles.informativeText}>
                        {data.informative_text.map((informative: { text: string }, i: number) => (
                            <p key={i}>{informative.text}</p>
                        )
                        )}
                    </div>
                }
                <section className={styles.frequentInformation}>
                    {data.frequent_information_title && <h2 className={styles.informationTitle}>{data.frequent_information_title[0]?.text}</h2>}
                    {data.frequent_information_description && <p className={styles.informationDescription}>{data.frequent_information_description[0]?.text}</p>}
                    {
                        data.frequent && <div className={styles.accordion}>{
                            data.frequent.map((information: { frequent_information: any[], frequent_title: any[] }, i:number) => (
                                <AccordionItem key={i} title={information.frequent_title[0].text} information={information.frequent_information[0].text} />
                            ))
                        }</div>

                    }
                </section>
                <section>
                    {data.frequently_asked_section_title && <h2 className={styles.frequentlyTitle}>{data.frequently_asked_section_title[0].text}</h2>}
                    {data.frequently_asked_section && data.frequently_asked_section.map((QA: { frequently_question: any[], frequently_answer: any[] }, i:number) => {
                        return <div key={i}>
                                    <h3 className={styles.question}>Q: {QA.frequently_question[0]?.text}</h3>
                                    <p className={styles.answer}>A: {QA.frequently_answer[0]?.text}</p>
                                </div>
                    })}
                </section>

            </main>
        </>
    );
};

export const query = graphql`
    query {
        allPrismicFaqsPage {
            edges {
                node {
                    dataString
                }
            }
        }
    }
`;
export default Faqs;
